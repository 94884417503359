//
// Draggable plugin customization: https://shopify.github.io/draggable
//
.draggable-container--is-dragging{
    border:1px dashed #0b0e18;
}

.draggable {
    transition: opacity 0.3s ease;
    outline: none !important;

    &.draggable-mirror {
        opacity: 0.8;
        transition: opacity 0.3s ease;
        border: 2px dashed $gray-300 !important;
    }

    &.draggable--original {
        opacity: .2 !important;
    }


    &.draggable-source--is-dragging.draggable--over {
        opacity: .3 !important;
        .card.card-bordered {
            border:1px dashed #EFF2F5
        }
    }

    // Handle
    .draggable-handle {
        cursor: move;
    }
}
